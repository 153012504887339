/**
=========================================================
* States Grid - v1.1.0
=========================================================

* Copyright 2024

* Coded by KanukPt
=========================================================

*/

import typography from "assets/theme/base/typography";
import pxToRem from "assets/theme/functions/pxToRem";

const { size } = typography;

export default {
  styleOverrides: {
    root: {
      padding: pxToRem(16),
      fontSize: size.xl,
    },
  },
};
