/*
=========================================================
* States Grid React - v1.1.0
=========================================================

* Copyright 2024

* Coded by KanukPt

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import AboutUs from "pages/LandingPages/AboutUs";

export default function AboutUsPage() {
    return <AboutUs />;
}
