/*
=========================================================
* States Grid - v1.1.0
=========================================================

* Copyright 2024

Coded by KanukPt

==========================================================

*/

// @mui material components
// import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "layouts/sections/page-sections/Navbars/DefaultNavbar";
// import DefaultFooter from "layouts/sections/page-sections/page-footer";
import Footer from "layouts/sections/page-sections/page-footer/sections/Footer";

// About Us page sections
// import Information from "pages/LandingPages/AboutUs/sections/Information";
// import Team from "pages/LandingPages/AboutUs/sections/Team";
import Overview from "pages/LandingPages/TermsOfUse/sections/Overview";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/princhouse-20240612-1400x600.jpg";
import {Helmet} from "react-helmet";
import {useLocation} from 'react-router-dom';

function TermsOfUse() {
	const loc = useLocation();
	return (
		<>
			<Helmet>
				<meta name="description" content="About us" />
                <link rel="canonical" href={loc.pathname} />
            </Helmet>
			<DefaultNavbar
				routes={routes}
				// transparent
				// light
				relative
				sticky
			/>
			<MKBox
				minHeight="75vh"
				width="100%"
				sx={{
					backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
						`${linearGradient(
							rgba(gradients.dark.main, 0.1),
							rgba(gradients.dark.state, 0.1)
						)}, url(${bgImage})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					display: "grid",
					placeItems: "center",
					// marginTop:"6.5rem",
				}}
			>
			</MKBox>
			{/* <Overview /> */}
			<Card
				sx={{
					p: 2,
					mx: { xs: 2, lg: 3 },
					mt: -8,
					mb: 4,
					boxShadow: ({ boxShadows: { xxl } }) => xxl,
				}}
			>
				<Overview />
			</Card>
			<Footer content={footerRoutes} />
		</>
	);
}

export default TermsOfUse;
