import ArticlesPagination from "../components/ArticlesPagination";
import ArticlesPreview from "../components/ArticlesPreview";
import { useFeedContext } from "../context/FeedContext";
import useArticleList from "../hooks/useArticles";
import {Spinner} from "components/spinner"

function BlogArticles() {
	const { tabName, tagName } = useFeedContext();
	// const tabName = "global"
	// const tagName = "";

	const { articles, articlesCount, loading, setArticlesData } = useArticleList({
		location: tabName,
		tabName,
		tagName,
	});

	return loading ? (
		<div className="article-preview">
		{/* <em>Loading articles list...</em> */}
		<Spinner size={16} showSpinner={loading} />
		</div>
	) : articles.length > 0 ? (
		<>
			<ArticlesPreview
				articles={articles}
				loading={loading}
				updateArticles={setArticlesData}
			/>

			<ArticlesPagination
				articlesCount={articlesCount}
				location={tabName}
				tagName={tagName}
				updateArticles={setArticlesData}
			/>
		</>
	) : (
		<div className="article-preview">Articles not available.</div>
	);
}

export default BlogArticles;
