/**
=========================================================
* States Grid React - v1.1.0
=========================================================

* Copyright 2024

Coded by KanukPt

=========================================================
*/

import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";  //, HashRouter
// import ReactDOM from 'react-dom/client'
import App from "App";


const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);

root.render(
	<BrowserRouter >
		<App />
	</BrowserRouter >
);
// ReactDOM.hydrateRoot(
// 	document.getElementById('root'),
// 	<React.StrictMode>
// 		<BrowserRouter >
// 	  		<App />
// 		</BrowserRouter>
// 	</React.StrictMode>
// )

  
