

import { createContext, useContext, useEffect, useState } from "react";
import getUser from "../services/getUser";

const AuthContext = createContext();

export function useAuth() {
	return useContext(AuthContext);
}

const loggedIn = JSON.parse(typeof window !== "undefined" ? window.localStorage.getItem("loggedUser") : null);

/* eslint-disable no-unused-vars, no-shadow */
const authState = {
	headers: null,
	isAuth: false,
	loggedUser: {
		bio: null,
		email: "",
		image: null,
		token: "",
		username: "",
	},
};


function AuthProvider({ children }) {
	// const { headers = null } = useAuth() || {};
	// const [{ headers = null, isAuth = false, loggedUser = "" }, setAuthState] = useState(
	//   loggedIn || authState,
	// );
	// console.dir(loggedIn);
	// console.dir(authState);
	const astate = useState(loggedIn || authState);
	// console.dir(astate);

	const [{ headers = null, isAuth = false, loggedUser = {} }, setAuthState] = useState(loggedIn || authState);
	// console.dir(isAuth)
	// console.dir(headers)
	// console.dir(loggedUser)
	
	useEffect(() => {
		if (!headers) return;
		// console.dir(headers)
		getUser({ headers })
		.then((loggedUser) => setAuthState((prev) => ({ ...prev, loggedUser })))
		.catch(console.error);
	}, [headers, setAuthState]);

	return (
		<AuthContext.Provider value={{ headers, isAuth, loggedUser, setAuthState }}>
			{children}
		</AuthContext.Provider>
	);
}

export default AuthProvider;
