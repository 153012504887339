// import { Link } from 'react-router-dom'
import { ArrowLink } from 'components/arrow-button'
import { Grid } from 'components/grid'
import { H2 } from 'components/typography'
// import Typography from "@mui/material/Typography";

function IntroductionSection() {
	return (
		<Grid>
			<div className="col-span-full mt-12 xs:col-start-6 lg:mt-0">
				<H2 id="intro">
					{`We are Ekambuli High School. We assist our learners in their effort to be of high quality.`}
				</H2>
				<div className="text-xl bg-secondary mx-auto w-full max-w-8xl rounded-lg">
					Ekambuli High School is among the actual giants in the schools category in western Kenya. 
					Through God&apos;s grace the school&apos;s dream came to reality with support of Mr. Hector Sutherland in 1980. 
					The robust, study institution came to life.
				</div>
				<ArrowLink
					to="/about-us"
					direction="right"
					className="mt-5"
					prefetch="intent"
				>
					Learn more about us
				</ArrowLink>
			</div>
		</Grid>
	)
}

export { IntroductionSection }
