/*
=========================================================
* States Grid React - v1.1.0
=========================================================

* Copyright 2024

* Coded by KanukPt

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Privacy from "pages/LandingPages/Privacy";

export default function PrivacyPage() {
    return <Privacy />;
}
