/**
=========================================================
* States Grid - v1.1.0
=========================================================

* Copyright 2024

* Coded by KanukPt
=========================================================

*/

import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

import pxToRem from "assets/theme/functions/pxToRem";

const { borderWidth } = borders;
const { light } = colors;

export default {
  styleOverrides: {
    root: {
      padding: `${pxToRem(12)} ${pxToRem(16)}`,
      borderBottom: `${borderWidth[1]} solid ${light.main}`,
    },
  },
};
