/*
=========================================================
* States Grid React - v1.1.0
=========================================================

* Copyright 2024

* Coded by KanukPtm

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import TermsOfUse from "pages/LandingPages/TermsOfUse";

export default function TermsOfUsePage() {
    return <TermsOfUse />;
}
