import { useFeedContext } from "../../context/FeedContext";
// import classNames from 'classnames'

function FeedNavLink({ icon, name, text }) {
	const { tabName, changeTab } = useFeedContext();

	const handleClick = (e) => {
		changeTab(e, name);
	};

	return (
		<li className="nav-item">
			{/* <button
				className={`nav-link ${tabName === name ? "active" : ""}`}
				onClick={handleClick}
			>
				{icon && <i className="ion-pound"></i>} {text}
			</button> */}

			<a style={{cursor:'pointer'}} className={`nav-link ${tabName === name ? "active" : ""}`} onClick={handleClick}>
				{icon && <i className="ion-pound"></i>} {text}
			</a>
		</li>
	);
}

export default FeedNavLink;
