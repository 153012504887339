/**
=========================================================
* States Grid - v1.1.0
=========================================================

* Copyright 2024

* Coded by KanukPt
=========================================================
*/

export default {
  defaultProps: {
    disableRipple: false,
  },
};
