// import { type HeadersFunction } from '@remix-run/node'
import { Link } from 'react-router-dom'
// import { captureRemixErrorBoundaryError } from '@sentry/remix'
import {
	format as dateFormat,
	add as dateAdd,
	parseISO as dateParseISO,
} from 'date-fns'
import md5 from 'md5-hash'
import * as React from 'react'
// import {
// 	type NonNullProperties,
// 	type OptionalTeam,
// 	type Role,
// 	type Team,
// 	type User,
// } from '#app/types.ts'
import { images } from 'images'
// import { type getEnv } from './env.server.ts'

const teams = ['RED', 'BLUE', 'YELLOW']
export const optionalTeams = [...teams, 'UNKNOWN']
const roles = ['ADMIN', 'MEMBER']
const isTeam = (team) => teams.includes(team)
const isRole = (role) => roles.includes(role)
const getTeam = (team) => (isTeam(team) ? team : null)
const getOptionalTeam = (team) => isTeam(team) ? team : 'UNKNOWN'

const defaultAvatarSize = 128
function getAvatar(
	email,
	{
		size = defaultAvatarSize,
		fallback = images.kodyProfileGray({ resize: { width: size } }),
		origin,
	}
) {
	const hash = md5(email)
	const url = new URL(`https://www.gravatar.com/avatar/${hash}`)
	url.searchParams.set('size', String(size))
	if (fallback) {
		if (origin && fallback.startsWith('/')) {
			fallback = `${origin}${fallback}`
		}
		url.searchParams.set('default', fallback)
	}
	return url.toString()
}

const avatarFallbacks = {
	BLUE: (width) => images.kodyProfileBlue({ resize: { width } }),
	RED: (width) => images.kodyProfileRed({ resize: { width } }),
	YELLOW: (width) => images.kodyProfileYellow({ resize: { width } }),
	UNKNOWN: (width) => images.kodyProfileGray({ resize: { width } }),
}

function getAvatarForUser(
	{ email, team, firstName },
	{ size = defaultAvatarSize, origin }
) {
	return {
		src: getAvatar(email, {
			fallback: avatarFallbacks[getOptionalTeam(team)](size),
			size,
			origin,
		}),
		alt: firstName,
	}
}

const teamTextColorClasses = {
	YELLOW: 'text-team-yellow',
	BLUE: 'text-team-blue',
	RED: 'text-team-red',
	UNKNOWN: 'text-team-unknown',
}

const teamDisplay = {
	RED: 'Red',
	BLUE: 'Blue',
	YELLOW: 'Yellow',
}

const useSSRLayoutEffect =
	typeof window === 'undefined' ? () => {} : React.useLayoutEffect

const AnchorOrLink = function AnchorOrLink({
	ref,
	...props
}) {
	const {
		to,
		href,
		download,
		reload = false,
		prefetch,
		children,
		...rest
	} = props
	let toUrl = ''
	let shouldUserRegularAnchor = reload || download

	if (!shouldUserRegularAnchor && typeof href === 'string') {
		shouldUserRegularAnchor = href.includes(':') || href.startsWith('#')
	}

	if (!shouldUserRegularAnchor && typeof to === 'string') {
		toUrl = to
		shouldUserRegularAnchor = to.includes(':')
	}

	if (!shouldUserRegularAnchor && typeof to === 'object') {
		toUrl = `${to.pathname ?? ''}${to.hash ? `#${to.hash}` : ''}${
			to.search ? `?${to.search}` : ''
		}`
		shouldUserRegularAnchor = to.pathname?.includes(':')
	}

	if (shouldUserRegularAnchor) {
		return (
			<a {...rest} download={download} href={href ?? toUrl} ref={ref}>
				{children}
			</a>
		)
	} else {
		return (
			<Link prefetch={prefetch} to={to ?? href ?? ''} {...rest} ref={ref}>
				{children}
			</Link>
		)
	}
}

function formatDuration(seconds) {
	const mins = Math.floor(seconds / 60)
		.toString()
		.padStart(2, '0')
	const secs = (seconds % 60).toFixed().toString().padStart(2, '0')
	return `${mins}:${secs}`
}

const formatNumber = (num) => new Intl.NumberFormat().format(num)

function formatAbbreviatedNumber(num) {
	return num < 1_000
		? formatNumber(num)
		: num < 1_000_000
			? `${formatNumber(Number((num / 1_000).toFixed(2)))}k`
			: num < 1_000_000_000
				? `${formatNumber(Number((num / 1_000_000).toFixed(2)))}m`
				: num < 1_000_000_000_000
					? `${formatNumber(Number((num / 1_000_000_000).toFixed(2)))}b`
					: 'a lot'
}

function formatDate(dateString, format = 'PPP') {
	if (typeof dateString !== 'string') {
		dateString = dateString.toISOString()
	}
	return dateFormat(parseDate(dateString), format)
}

function parseDate(dateString) {
	return dateAdd(dateParseISO(dateString), {
		minutes: new Date().getTimezoneOffset(),
	})
}

function getErrorMessage(error, fallback = 'Unknown Error') {
	if (typeof error === 'string') return error
	if (error instanceof Error) return error.message
	return fallback
}

function getErrorStack(error, fallback = 'Unknown Error') {
	if (typeof error === 'string') return error
	if (error instanceof Error) return error.stack
	return fallback
}

function getNonNull (
	obj,
) {
	for (const [key, val] of Object.entries(obj)) {
		assertNonNull(val, `The value of ${key} is null but it should not be.`)
	}
	return obj
}

function typedBoolean(
	value,
) {
	return Boolean(value)
}

function assertNonNull(
	possibleNull,
	errorMessage,
) {
	if (possibleNull == null) throw new Error(errorMessage)
}

function getRequiredEnvVarFromObj(
	obj,
	key,
	devValue = `${key}-dev-value`,
) {
	let value = devValue
	const envVal = obj[key]
	if (envVal) {
		value = envVal
	} else if (obj.NODE_ENV === 'production') {
		throw new Error(`${key} is a required env variable`)
	}
	return value
}

function getRequiredServerEnvVar(key, devValue) {
	return getRequiredEnvVarFromObj(process.env, key, devValue)
}

function getRequiredGlobalEnvVar(
	key,
	devValue,
) {
	return getRequiredEnvVarFromObj(process.ENV, key, devValue)
}

function getDiscordAuthorizeURL(domainUrl) {
	const url = new URL('https://discord.com/api/oauth2/authorize')
	url.searchParams.set(
		'client_id',
		getRequiredGlobalEnvVar('DISCORD_CLIENT_ID'),
	)
	url.searchParams.set('redirect_uri', `${domainUrl}/discord/callback`)
	url.searchParams.set('response_type', 'code')
	url.searchParams.set('scope', 'identify guilds.join email guilds')
	return url.toString()
}

/**
 * @returns domain URL (without a ending slash, like: https://kentcdodds.com)
 */
function getDomainUrl(request) {
	const host =
		request.headers.get('X-Forwarded-Host') ?? request.headers.get('host')
	if (!host) {
		throw new Error('Could not determine domain URL.')
	}
	const protocol = host.includes('localhost') ? 'http' : 'https'
	return `${protocol}://${host}`
}

export function isResponse(response) {
	return (
		typeof response === 'object' &&
		response !== null &&
		'status' in response &&
		'headers' in response &&
		'body' in response
	)
}

function removeTrailingSlash(s) {
	return s.endsWith('/') ? s.slice(0, -1) : s
}

function getDisplayUrl(requestInfo) {
	return getUrl(requestInfo).replace(/^https?:\/\//, '')
}

function getOrigin(requestInfo) {
	return requestInfo?.origin ?? 'https://kentcdodds.com'
}

function getUrl(requestInfo) {
	return removeTrailingSlash(
		`${getOrigin(requestInfo)}${requestInfo?.path ?? ''}`,
	)
}

function toBase64(string) {
	if (typeof window === 'undefined') {
		return Buffer.from(string).toString('base64')
	} else {
		return window.btoa(string)
	}
}

function useUpdateQueryStringValueWithoutNavigation(
	queryKey,
	queryValue,
) {
	React.useEffect(() => {
		const currentSearchParams = new URLSearchParams(window.location.search)
		const oldQuery = currentSearchParams.get(queryKey) ?? ''
		if (queryValue === oldQuery) return

		if (queryValue) {
			currentSearchParams.set(queryKey, queryValue)
		} else {
			currentSearchParams.delete(queryKey)
		}
		const newUrl = [window.location.pathname, currentSearchParams.toString()]
			.filter(Boolean)
			.join('?')
		// alright, let's talk about this...
		// Normally with remix, you'd update the params via useSearchParams from react-router-dom
		// and updating the search params will trigger the search to update for you.
		// However, it also triggers a navigation to the new url, which will trigger
		// the loader to run which we do not want because all our data is already
		// on the client and we're just doing client-side filtering of data we
		// already have. So we manually call `window.history.pushState` to avoid
		// the router from triggering the loader.
		window.history.replaceState(null, '', newUrl)
	}, [queryKey, queryValue])
}

function debounce(
	fn,
	delay,
) {
	let timer = null
	return (...args) => {
		if (timer) clearTimeout(timer)
		timer = setTimeout(() => {
			fn(...args)
		}, delay)
	}
}

function useDebounce(callback, delay) {
	const callbackRef = React.useRef(callback)
	React.useEffect(() => {
		callbackRef.current = callback
	})
	return React.useMemo(
		() =>
			debounce(
				(...args) => callbackRef.current(...args),
				delay,
			),
		[delay],
	)
}

const reuseUsefulLoaderHeaders = ({
	loaderHeaders,
	parentHeaders,
}) => {
	const headers = new Headers()
	const usefulHeaders = ['Cache-Control', 'Vary', 'Server-Timing']
	for (const headerName of usefulHeaders) {
		if (loaderHeaders.has(headerName)) {
			headers.set(headerName, loaderHeaders.get(headerName))         //!
		}
	}
	const appendHeaders = ['Server-Timing']
	for (const headerName of appendHeaders) {
		if (parentHeaders.has(headerName)) {
			headers.append(headerName, parentHeaders.get(headerName))       //!
		}
	}
	const useIfNotExistsHeaders = ['Cache-Control', 'Vary']
	for (const headerName of useIfNotExistsHeaders) {
		if (!headers.has(headerName) && parentHeaders.has(headerName)) {
			headers.set(headerName, parentHeaders.get(headerName))         //!
		}
	}

	return headers
}

function callAll(
	...fns
) {
	return (...args) => fns.forEach((fn) => fn?.(...args))
}

function useDoubleCheck() {
	const [doubleCheck, setDoubleCheck] = React.useState(false)

	function getButtonProps(props) {
		const onBlur = () =>
			setDoubleCheck(false)

		const onClick = doubleCheck
			? undefined
			: (e) => {
					e.preventDefault()
					setDoubleCheck(true)
				}

		return {
			...props,
			onBlur: callAll(onBlur, props?.onBlur),
			onClick: callAll(onClick, props?.onClick),
		}
	}

	return { doubleCheck, getButtonProps }
}

/**
 * Provide a condition and if that condition is falsey, this throws an error
 * with the given message.
 *
 * inspired by invariant from 'tiny-invariant' except will still include the
 * message in production.
 *
 * @example
 * invariant(typeof value === 'string', `value must be a string`)
 *
 * @param condition The condition to check
 * @param message The message to throw (or a callback to generate the message)
 * @param responseInit Additional response init options if a response is thrown
 *
 * @throws {Error} if condition is falsey
 */
export function invariant(condition, message,) {
	if (!condition) {
		throw new Error(typeof message === 'function' ? message() : message)
	}
}

/**
 * Provide a condition and if that condition is falsey, this throws a 400
 * Response with the given message.
 *
 * inspired by invariant from 'tiny-invariant'
 *
 * @example
 * invariantResponse(typeof value === 'string', `value must be a string`)
 *
 * @param condition The condition to check
 * @param message The message to throw (or a callback to generate the message)
 * @param responseInit Additional response init options if a response is thrown
 *
 * @throws {Response} if condition is falsey
 */
export function invariantResponse(
	condition,
	message,
	responseInit,
) {
	if (!condition) {
		throw new Response(typeof message === 'function' ? message() : message, {
			status: 400,
			...responseInit,
		})
	}
}

// export function useCapturedRouteError() {
// 	const error = useRouteError()
// 	// captureRemixErrorBoundaryError(error)
// 	return error
// }

export function requireValidSlug(slug) {
	if (typeof slug !== 'string' || !/^[a-zA-Z0-9-_.]+$/.test(slug)) {
		throw new Response(`This is not a valid slug: "${slug}"`, { status: 400 })
	}
}

export { listify } from 'utils/listify'
export {
	getAvatar,
	getAvatarForUser,
	AnchorOrLink,
	getErrorMessage,
	getErrorStack,
	getNonNull,
	assertNonNull,
	useUpdateQueryStringValueWithoutNavigation,
	useSSRLayoutEffect,
	useDoubleCheck,
	useDebounce,
	typedBoolean,
	getRequiredServerEnvVar,
	getRequiredGlobalEnvVar,
	getDiscordAuthorizeURL,
	getDomainUrl,
	getUrl,
	getDisplayUrl,
	getOrigin,
	toBase64,
	removeTrailingSlash,
	reuseUsefulLoaderHeaders,
	teams,
	isTeam,
	isRole,
	getTeam,
	getOptionalTeam,
	teamDisplay,
	teamTextColorClasses,
	parseDate,
	formatDate,
	formatDuration,
	formatNumber,
	formatAbbreviatedNumber,
}

