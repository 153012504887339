/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function DefaultInfoCard({ color="info", icon, title, description, direction="left", small }) {
	return (
		<MKBox lineHeight={1} p={direction === "center" ? 2 : 0} textAlign={direction} mt={direction === "center" ? 0 : 3}>
			{typeof icon === "string" ? (
				<MKTypography
					display="block"
					variant={direction === "center" ? "h2" : "h3"}
					color={color}
					textGradient
					>
					{" "}
					<Icon>{icon}</Icon>{" "}
				</MKTypography>
			) : (
				icon
			)}
			<MKTypography
				display="block"
				variant="5"
				fontWeight="bold"
				mt={direction === "center" ? 1 : 1}
				mb={1}
			>
				{title}
			</MKTypography>
			<MKTypography
				display="block"
				variant={small ? "button" : "body2"}
				color="text"
				pr={direction === "left" ? 6 : 0}
				pl={direction === "right" ? 6 : 0}
			>
				{description}
			</MKTypography>
		</MKBox>
	);
}

// Setting default props for the DefaultInfoCard
// DefaultInfoCard.defaultProps = {
// 	color: "info",
// 	direction: "left",
// 	small: false,
// };

// Typechecking props for the DefaultInfoCard
DefaultInfoCard.propTypes = {
	color: PropTypes.oneOf([
		"primary",
		"secondary",
		"info",
		"success",
		"warning",
		"error",
		"light",
		"dark",
	]),
	icon: PropTypes.node.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	direction: PropTypes.oneOf(["left", "right", "center"]),
	small: PropTypes.bool,
};

export default DefaultInfoCard;
