// import { Outlet } from "react-router-dom";
// import BannerContainer from "../components/BannerContainer";
import ContainerRow from "../components/ContainerRow";
import FeedToggler from "../components/FeedToggler";
// import { useAuth } from "../context/AuthContext";
import FeedProvider from "../context/FeedContext";
import PopularTags from "../components/PopularTags";
import BlogArticles from "./BlogArticles";
import 'assets/css/main.css';
// import 'assets/css/ionicons.min.css';

// import classes from '../../../../../assets/css/article.css';
// import footerRoutes from "footer.routes";
// import Footer from "layouts/sections/page-sections/page-footer/sections/Footer";

function BlogHome() {
	// const { isAuth } = useAuth();

	return (
		<ContainerRow type="page">
			<FeedProvider>
				<div className="col-md-9">
					<FeedToggler />
					<BlogArticles />
				</div>
				<PopularTags />
			</FeedProvider>
		</ContainerRow>
	);
}

export default BlogHome;
